var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{staticClass:"px-0",attrs:{"md8":"","mx-auto":""}},[_c('div',{staticClass:"overflow-y-auto overflow-x-hidden px-5",class:_vm.loading.state === false && _vm.chats.length <= 0
        ? 'd-flex align-center justify-center'
        : '',style:({
      'min-height': _vm.chats.length > 0 ? '75vh' : '60vh',
      'max-height': _vm.chats.length > 0 ? '75vh' : '60vh'
    })},[(_vm.chats && _vm.chats.length > 0)?_c('chat',{attrs:{"disable-user-list-toggle":_vm.selectedChat === '' ? true : false,"participants":_vm.participants,"title-image-url":_vm.selectedChat
          ? ("https://firebasestorage.googleapis.com/v0/b/piletivahetus-develop.appspot.com/o/events%2Fjpg%2Fg_e_5h4234h" + (_vm.selectedChat.event.globalEventId) + "h4234h.jpg?alt=media")
          : '',"title":_vm.chatTitle,"on-message-was-sent":_vm.onMessageWasSent,"message-list":_vm.filteredMessages,"new-messages-count":_vm.newMessagesCount,"is-open":_vm.isChatOpen,"close":_vm.closeChat,"open":_vm.openChat,"show-emoji":true,"show-file":true,"show-typing-indicator":_vm.showTypingIndicator,"colors":_vm.colors,"always-scroll-to-bottom":_vm.scrollToBottom,"message-styling":true,"author-id":_vm.user.id,"chats":_vm.chats,"show-chats-list":_vm.selectedChat === '' ? true : false,"chats-list-enabled":true,"user-list-enabled":false,"did-toggle-list":_vm.didToggleList,"on-chat-click":_vm.onChatClick,"on-chat-action-click":_vm.didClickChatAction,"payment-form-dialog":_vm.paymentFormDialog,"placeholder":_vm.$vuetify.lang.t('$vuetify.messages.writeAReply')},on:{"scrollToTop":_vm.handleScroll}}):_vm._e(),(_vm.loading.state === false && _vm.chats.length <= 0)?_c('empty-state-card',{staticClass:"empty-state",attrs:{"name":"chats-empty","title":_vm.$vuetify.lang.t('$vuetify.emptyState.noMessagesTitle'),"icon":'mdi-message-text',"color":"transparent","message":_vm.$vuetify.lang.t('$vuetify.emptyState.noMessagesMessage')}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }