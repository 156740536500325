<template>
  <v-flex md8 mx-auto class="px-0">
    <div
      :class="
        loading.state === false && chats.length <= 0
          ? 'd-flex align-center justify-center'
          : ''
      "
      class="overflow-y-auto overflow-x-hidden px-5"
      :style="{
        'min-height': chats.length > 0 ? '75vh' : '60vh',
        'max-height': chats.length > 0 ? '75vh' : '60vh'
      }"
    >
      <chat
        v-if="chats && chats.length > 0"
        :disable-user-list-toggle="selectedChat === '' ? true : false"
        :participants="participants"
        :title-image-url="
          selectedChat
            ? `https://firebasestorage.googleapis.com/v0/b/piletivahetus-develop.appspot.com/o/events%2Fjpg%2Fg_e_5h4234h${selectedChat.event.globalEventId}h4234h.jpg?alt=media`
            : ''
        "
        :title="chatTitle"
        :on-message-was-sent="onMessageWasSent"
        :message-list="filteredMessages"
        :new-messages-count="newMessagesCount"
        :is-open="isChatOpen"
        :close="closeChat"
        :open="openChat"
        :show-emoji="true"
        :show-file="true"
        :show-typing-indicator="showTypingIndicator"
        :colors="colors"
        :always-scroll-to-bottom="scrollToBottom"
        :message-styling="true"
        :author-id="user.id"
        :chats="chats"
        :show-chats-list="selectedChat === '' ? true : false"
        :chats-list-enabled="true"
        :user-list-enabled="false"
        :did-toggle-list="didToggleList"
        :on-chat-click="onChatClick"
        :on-chat-action-click="didClickChatAction"
        :payment-form-dialog="paymentFormDialog"
        :placeholder="$vuetify.lang.t('$vuetify.messages.writeAReply')"
        @scrollToTop="handleScroll"
      />
      <empty-state-card
        v-if="loading.state === false && chats.length <= 0"
        name="chats-empty"
        :title="$vuetify.lang.t('$vuetify.emptyState.noMessagesTitle')"
        :icon="'mdi-message-text'"
        color="transparent"
        :message="$vuetify.lang.t('$vuetify.emptyState.noMessagesMessage')"
        class="empty-state"
      ></empty-state-card>
      <!-- <payment-form-dialog v-if="user" v-model="paymentFormDialog" /> -->
    </div>
  </v-flex>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    Chat: () =>
      import(/* webpackChunkName: "Chat" */ '@/components/Chat/Launcher'),
    EmptyStateCard: () =>
      import(
        /* webpackChunkName: "EmptyStateCard" */ '@/components/EmptyStateCard'
      )
    // PaymentFormDialog: () =>
    //   import(
    //     /* webpackChunkName: "PaymentFormDialog" */ '@/components/PaymentFormDialog'
    //   )
  },
  data() {
    return {
      newMessagesCount: 0,
      isChatOpen: true, // to determine whether the chat window should be open or closed
      showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
      colors: {
        header: {
          bg: '#003650',
          text: '#ffffff'
        },
        launcher: {
          bg: '#4e8cff'
        },
        messageList: {
          bg: '#ffffff'
        },
        sentMessage: {
          bg: '#003650',
          text: '#ffffff'
        },
        receivedMessage: {
          bg: '#eaeaea',
          text: '#222222'
        },
        userInput: {
          bg: '#f4f7f9',
          text: '#565867'
        }
      }, // specifies the color scheme for the component
      chatTitle: '',
      scrollToBottom: true,
      paymentFormDialog: false
    }
  },
  computed: {
    ...mapGetters('authentication', ['user']),
    ...mapGetters('app', ['loading']),
    ...mapGetters('chats', [
      'chats',
      'participants',
      'filteredMessages',
      'selectedChat',
      'observers'
    ]),
    ...mapGetters('payments', ['sources'])
  },
  methods: {
    ...mapActions('chats', [
      'sendMessage',
      'setItemStatus',
      'getMoreChatMessages'
    ]),
    showSnackbar(message) {
      this.$store.commit(
        'app/setSnackbar',
        {
          show: true,
          type: 'error',
          message
        },
        { root: true }
      )
    },
    async didClickChatAction(item) {
      // await this.getAllUserSources()
      // console.log('tere', item)
      // const sendMoneyTo = this.participants.find(participant => {
      //   participant.id === item.receiverId
      // })
      // console.log(item.receiverId)
      // Ostja
      if (item.receiverId !== this.user.id && item.isSellable) {
        this.setSelectedChat(item)
        // const newCharge = new StripeChargesDB().create({
        //   source: '',
        //   amount: 20,
        //   customer:
        // })
        // this.paymentFormDialog = true
      } else if (item.receiverId === this.user.id && !item.isSellable) {
        // console.log('MAKING AS SELLABLE')
        // if (this.user.bankAccountNumber) {
        //   // this.setSelectedChat(item)
        //   this.setItemStatus({ chatId: item.id, isSellable: true })
        // } else if (!this.user.bankAccountNumber) {
        //   // console.log('tere')

        //   this.paymentFormDialog = true
        // }
        this.setItemStatus({ chatId: item.id, isSellable: true })
      } else if (item.receiverId === this.user.id && item.isSellable) {
        // console.log('MAKE IT UNSELLABLE')
        this.setItemStatus({ chatId: item.id, isSellable: false })
      }
    },
    didToggleList() {
      this.$store.commit('chats/setSelectedChat', '')
      this.$store.commit('chats/clearMessages')
      this.observers.forEach(observer => {
        if (observer.from === 'readAllSnapshots') {
          observer.listener()
        }
      })
    },
    onChatClick(item) {
      this.setSelectedChat(item)
    },
    async setSelectedChat(item) {
      const selectedChat = this.chats.find(obj => {
        return obj.id === item.id
      })
      this.$store.commit('chats/setSelectedChat', selectedChat)
      this.$store.dispatch('chats/getChatMessages')
      await this.$store.dispatch('chats/getParticipantsInfo')
      if (this.participants) {
        const receiver = this.participants.find(
          participant => participant.id !== this.user.id
        )
        this.chatTitle = receiver
          ? `${this.$vuetify.lang.t('$vuetify.messages.you')} & ${
              receiver.name
            }`
          : 'Messages'
      }
      this.openChat()
    },
    // sendMessage(text) {
    //   if (text.length > 0) {
    //     this.newMessagesCount = this.isChatOpen
    //       ? this.newMessagesCount
    //       : this.newMessagesCount + 1
    //     this.onMessageWasSent({
    //       author: 'support',
    //       type: 'text',
    //       data: { text }
    //     })
    //   }
    // },
    onMessageWasSent(message) {
      // called when the user sends a message
      this.$store.dispatch('chats/sendMessage', message)
    },
    openChat() {
      // called when the user clicks on the fab button to open the chat
      this.isChatOpen = true
      this.newMessagesCount = 0
    },
    closeChat() {
      // called when the user clicks on the botton to close the chat
      this.isChatOpen = false
      this.$store.commit('chats/setSelectedChat', '')
    },
    handleScroll() {
      this.scrollToBottom = false
      this.getMoreChatMessages(this.selectedChat.id)
      // called when the user scrolls message list to top
      // leverage pagination for loading another page of messages
    }
  }
}
</script>

<style>
.sc-header--img {
  height: 54px !important;
  width: 54px !important;
  object-fit: cover;
}
.chat-list table {
  width: 100%;
  padding-right: 8px;
}
.sc-user-input--text {
  width: calc(100% - 73px);
}
.sc-user-input--text:empty:before {
  cursor: text;
}
.sc-chat-window {
  z-index: 9999;
  position: initial !important;
  height: 64vh;
  width: 100% !important;
}
.sc-chat-window.opened {
  height: 64vh;
}
.theme--light.v-tabs-items {
  background-color: transparent;
}
.sc-launcher {
  display: none;
}
@media screen and (min-width: 500px) {
  .sc-header--close-button {
    display: none;
  }
}
</style>
